const validators = {
  isBusinessId: async (value) => {
    return /^[0-9]{7}\-[0-9]{1}$/.test(value);
  },

  isColor: async (value) => {
    return /^#[0-9a-fA-F]{6}$/.test(value);
  },

  isEmail: async (value) => {
    return /^\S+@\S+\.\S+$/.test(value);
  },

  isMatch: async (value, comparable) => {
    return value === comparable;
  },

  isNumber: async (value) => {
    return !isNaN(value);
  },

  isPhoneNumber: async (value) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
      value
    );
  },

  isPostalCode: async (value) => {
    return /^[0-9]{5}$/.test(value);
  },

  isRequired: async (value) => {
    return value && value.toString().length;
  },
};

const validate = async (rules, value, match) => {
  for (const rule of rules) {
    let valid = true;
    if (rule == "isMatch") {
      valid = await validators[rule](value, match);
    } else {
      valid = await validators[rule](value);
    }
    if (!valid) return rule;
  }
};

export default validate;
