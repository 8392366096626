<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.manage_estate_partners") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div
      class="card col-span-12 flex flex-col justify-between p-4 xl:col-span-4"
    >
      <h2>{{ t("headings.partner_companies") }}:</h2>
      <div class="grid grid-cols-1 gap-2 p-2 lg:grid-cols-2 xl:grid-cols-3">
        <div
          v-if="estatePartners"
          v-for="partner in estatePartners"
          :key="partner.id"
          class="flex flex-col"
        >
          <div class="items-center gap-1 border-2 p-3">
            <label
              class="cursor-pointer hover:text-blue-500"
              @click="router.push('/partners/' + partner.id)"
              >{{ partner.name }}</label
            ><br />
            <span>{{
              partner.types
                .map((partnerType) =>
                  t("partner_types." + getTypeName(partnerType, types))
                )
                .join(", ")
            }}</span
            ><br />
            <span>{{ partner.email }}</span
            ><br />
            <span>{{ partner.phone }}</span
            ><br />
            <div class="flex justify-end">
              <button
                class="focus:outline-none justify-content-right rounded-full bg-rose-500 p-2 font-semibold text-white hover:bg-rose-600 focus:ring focus:ring-rose-300 disabled:bg-rose-500"
                type="button"
                @click="removeEstatePartner(partner.id)"
              >
                <TrashIcon class="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4 border-slate-300" />
      <h2>{{ t("labels.add_partner") }}</h2>
      <div class="grid grid-cols-12">
        <div
          class="col-span-12 sm:px-2 md:col-span-10 lg:col-span-9 xl:col-span-7"
        >
          <div class="my-3 justify-between sm:flex">
            <label class="mb-1 block w-full sm:w-1/2">{{
              t("labels.type")
            }}</label>
            <form-select
              class="w-full"
              :allOption="true"
              id="partner-filter"
              :invalid="false"
              :optionLabel="['label']"
              :options="types"
              translatableLabel="partner_types"
              v-model="selectedType"
            />
          </div>
        </div>
        <div
          class="col-span-12 sm:px-2 md:col-span-10 lg:col-span-9 xl:col-span-7"
        >
          <hr class="my-2 w-full border-t border-gray-300" />
          <div class="my-3 justify-between sm:flex">
            <label class="mb-1 block w-full sm:w-1/2">{{
              t("labels.company")
            }}</label>
            <form-select
              class="w-full"
              id="partner-select"
              :invalid="state.partnerId.error ? true : false"
              :optionLabel="['name']"
              :options="filteredPartners"
              v-model="state.partnerId.value"
            />
          </div>
        </div>
      </div>
      <hr class="my-2 w-full border-t border-gray-300" />
      <div class="flex justify-end">
        <form-button
          class="mt-2"
          :command="() => handleSubmit()"
          id="add-estate-partner-button"
          label="add"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import FormSelect from "../components/form/FormSelect.vue";
import { getTypeName } from "../utils/typeUtils";
import validate from "../utils/validators";
export default {
  components: { Breadcrumb, FormButton, FormSelect, TrashIcon },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const partners = computed(() => store.state.partner.partners);
    const types = computed(() => store.state.partner.types);

    const selectedType = ref(null);

    const estatePartners = computed(() => {
      let partnerContainer = [];
      for (const partner of partners.value) {
        if (
          estate.value.partnerIds &&
          estate.value.partnerIds.includes(partner.id)
        ) {
          partnerContainer.push(partner);
        }
      }
      return partnerContainer;
    });

    const filteredPartners = computed(() => {
      if (!selectedType.value)
        return partners.value.filter((partner) => {
          if (!estate.value.partnerIds) {
            return true;
          } else {
            return !estate.value.partnerIds.includes(partner.id);
          }
        });
      return partners.value.filter(
        (partner) =>
          partner.types.includes(selectedType.value) &&
          !estate.value.partnerIds.includes(partner.id)
      );
    });

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.partner.waiting) return true;
      return false;
    });

    const state = reactive({
      partnerId: {
        error: null,
        rules: ["isRequired"],
        value: null,
      },
    });

    const isFormValid = async (isValid = true) => {
      for (const [_, value] of Object.entries(state)) {
        value.error = await validate(value.rules, value.value);
        if (value.error) isValid = false;
      }
      return isValid;
    };

    const handleSubmit = async () => {
      const isValid = await isFormValid();
      if (!isValid) return;
      const data = {};
      for (const [key, value] of Object.entries(state)) {
        data[key] = value.value;
      }
      store.dispatch("estate/addPartner", {
        estateId: estate.value.id,
        partnerId: data.partnerId,
      });
    };

    const removeEstatePartner = (partnerId) => {
      store.dispatch("estate/removePartner", {
        estateId: estate.value.id,
        partnerId,
      });
    };

    onMounted(() => {
      if (!estate.value.id || estate.value.id != route.params.estateId) {
        store.dispatch("estate/getEstate", route.params.estateId);
      } else {
        store.dispatch("partner/getPartners");
      }
      if (!types.value || !types.value.length) {
        store.dispatch("partner/getTypes");
      }
    });

    watch(
      () => estate.value,
      (estate) => {
        if (estate.id) {
          store.dispatch("partner/getPartners");
        }
      }
    );

    watch(
      () => selectedType.value,
      () => {
        state.partnerId.value = null;
      }
    );

    return {
      company,
      estate,
      estatePartners,
      filteredPartners,
      getTypeName,
      handleSubmit,
      partners,
      removeEstatePartner,
      route,
      router,
      selectedType,
      state,
      t,
      types,
      waiting,
    };
  },
};
</script>
